@import '../../../../styles/variables';

.placeHolderRootContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 720px;
    max-height: 60vh;
    background-size: contain;
    height: 60vw;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    object-fit: cover;

    img {
        width: 100%;
        max-width: 720px;
    }
}