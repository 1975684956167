@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins/paragraph';
@import '../../../../../../styles/mixins/media-queries';

.projectBlockRootContainer {
    @include smMax {
        grid-template-columns: 1fr;
        margin-bottom: 30px;
    }
    display: grid;
    grid-template-columns: 50% 50%;

    .imageContainer {
        @include smMax {
            height: 250px;
            width: 100%;
            margin: 20px 0;
        }
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        order: 1;

        &.toRight {
            order: 2;
        }
    }

    h1 {
        @include paragraph(30px, #3F20A4, 1);
        margin-bottom: 30px;
        font-weight: 300;
    }

    p {
        @include paragraph(15px, #2C2C2C, 1);
        letter-spacing: 0.15px;
        line-height: 1.9;
    }

    .metaContainer {
        @include smMax {
            padding: 0;
        }

        padding: 170px;
        order: 2;

        &.toLeft {
            order: 1;
        }
    }

    &:last-of-type {
        padding-bottom: 100px;
    }
}