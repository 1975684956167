@import "../../../styles/mixins/paragraph";

.metaContainer {
    padding: 20px;
    background: #fff;
    width: max-content;

    .projectTitle {
        color: #3F20A4;
        font-size: 35px;
        margin: 0;
        margin-bottom: 10px;
    }

    .tags {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            @include paragraph(13px, "#000", 19px);
        }
    }
}