.headerRootContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        padding: 10px 0;
        max-width: 40px;
        max-height: 40px;

        &:hover {
            cursor: pointer;
        }
    }
}