/* SIZES */

$home-footer-height: 100px;
$feed-bottom-nav-height: 50px;
$feed-medium-sidebar-width: 200px;
$feed-medium-post-container-width: 640px;
$sub-logo-container-height: 50px;

/* BREAKPOINTS */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1800px;

/* COLORS */

$main-gray-bg-color: #F6F6F6;