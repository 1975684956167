@import '../../styles/variables';
@import '../../styles/mixins/paragraph';

.homePageRootContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
    }

    .introTextContainer {
        max-width: 720px;
        padding-top: 20px;
        .introText {
            @include paragraph(18px, #2C2C2C, 30px);
            text-align: center;
        }
    }
}