@import '../../../../../../styles/mixins/paragraph';
@import '../../../../../../styles/mixins/media-queries';

.feedSidebarRootContainer {
    @include smMax {
        display: none;
    }

    max-width: 200px;
    margin: 40px 0;

    .header {
        .title {
            @include paragraph(13px, #F70237);
            font-weight: 600;
            margin: 0;
        }
    }

    .postNavigationContainer {
        .postNavList {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin-bottom: 30px;
                a {
                    @include paragraph(13px, #F70237);
                    font-weight: 600;
                    letter-spacing: 0.13px;
                    text-decoration: none;
                }

                .meta {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;

                    p {
                        @include paragraph(13px, #2C2C2C);
                        letter-spacing: 0.13px;
                    }
                }
            }
        }
    }
}