@import '../../styles/mixins/media-queries';

.outerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectsRootContainer {
    @include smMax {
        padding: 50px 20px;
        gap: 20px;
    }

    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    gap: 20px;
    padding: 100px;
    box-sizing: border-box;
    height: 90vh;
}