@import '../../styles/mixins/media-queries';

.oneProject {
    @include smMax {
        min-width: 280px;
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: red;
    min-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
    padding-left: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    &:hover {
        cursor: pointer;
    }
}