@import '../../../../styles/variables';
@import '../../../../styles/mixins/media-queries';

.mediumRoot {
    @include smMax {
        margin: 20px 20px;
    }
    margin: 100px auto;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
    .mediumInnerContainer {
        @include smMax {
            grid-template-columns: unset;
        }
        display: grid;
        column-gap: 30px;
        grid-template-columns: 200px auto;
        max-width: calc(#{$feed-medium-sidebar-width} + #{$feed-medium-post-container-width});
        margin: 0 auto;
    }
}