@import '../../../../styles/mixins/paragraph';
@import '../../../../styles/mixins/media-queries';

.instagramRootContainer {
    max-width: 100vw;
    max-height: calc(100vh - 180px);
    overflow: hidden;
}

.gridCol {
    padding-left: 20px;
    background-clip: padding-box;
}

.grid {
    display: flex;
    margin-left: 20px;
    width: auto;
    overflow-y: auto;
    max-height: inherit;

    img {
        max-width: 100%;
        height: auto;
    }
}

.caption {
    @include paragraph(13px, #2C2C2C);
    margin: 10px 0;
}

.masonry {
    overflow-y: auto;
    height: calc(100vh - 100px);
    
    @include smMax {
        display: none;
    }
}

.masonryMobile {
    display: none;

    @include smMax {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 100px);
        box-sizing: border-box;
        padding: 10px;

        div {
            img {
                max-width: 100%;
            }
        }
    }
}