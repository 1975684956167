@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins/paragraph';
@import '../../../../../../styles/mixins/media-queries';

.heroRootContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 150px 0;

    h1 {
        @include paragraph(80px, #3F20A4, 1);
    }

    .tagContainer {
        margin-top: 30px;
        margin-bottom: 40px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;

            li {
                @include paragraph(18px, #3F20A4, 1);
                position: relative;
                margin: 0 10px;

                &:after {
                    content: '';
                    position: absolute;
                    right: -10px;
                    width: 5px;
                    height: 5px;
                    background: rgba(#3F20A4, 1);
                    border-radius: 50%;
                    top: 7px;
                }

                &:last-child {
                    &:after {
                        content: unset;
                    }
                }
            }
        }
    }

    .descriptionContainer {
        @include smMax {
            padding: 0 20px;
        }
        max-width: 600px;
        text-align: center;

        p {
            @include paragraph(15px, #2C2C2C, 1);
            line-height: 25px;
        }
    }
}