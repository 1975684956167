@import '../../styles/variables';
@import '../../styles/mixins/media-queries';

.bottomNavigatorContainer {
    @include mdMax {
        flex-direction: column;
        height: initial;
    }
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $home-footer-height;
    background: #fff;

    &.subpage {
        height: $home-footer-height * 0.5;
        @include mdMax {
            flex-direction: row;
            a {
                flex: 1 1 0px;
                margin-bottom: 0;
            }
        }
    }
}