@import './fonts';

@mixin paragraph($size, $color, $line-height: 1) {
    color: $color;
    font-family: 'Sora', sans-serif;
    font-size: $size;
    line-height: $line-height;
    margin: 0;
    padding: 0
}
