@import '../../../../styles/mixins/paragraph';
@import '../../../../styles/mixins/media-queries';

.youtubeRootContainer {
    display: flex;
    justify-content: center;
    max-height: calc(100vh - 110px);
    overflow: hidden;
    overflow-y: auto;
    align-items: end;
    margin-top: 50px;

    .videoList {
        @include smMax {
            width: 90vw;
        }
        list-style-type: none;
        width: 50vw;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
            img {
                max-width: 100%;
            }

            .metaContainer {
                margin: 20px 0;
                margin-bottom: 50px;

                .title {
                    @include paragraph(20px, #F70237);
                }

                .meta {
                    display: flex;
                    justify-content: space-between;
                    span {
                        @include paragraph(13px, #2C2C2C);
                    }
                }
            }
        }
    }
}