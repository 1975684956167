@import '../../../../styles/variables';
@import '../../../../styles/mixins/paragraph';

.feedBottomNavigatorRoot {
    display: flex;
    height: $feed-bottom-nav-height;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 50px;
    background: rgba(#F70237, 1);

    span {
        a {
            @include paragraph(13px, #fff);
        }
    }
}