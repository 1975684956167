@import '../../../../styles/mixins/paragraph';
@import '../../../../styles/mixins/media-queries';
@import '../../../../styles/variables';

.singleMenuItem {
    @include mdMax {
        margin-bottom: 20px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    @include paragraph(23px, #000, 25px);
    text-decoration: none;
    font-weight: 400;
    position: relative;

    &.subpage {
        position: relative;
    }

    &.feed {
        @include paragraph(13px, #fff);
    }

    .activeIndicator {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(#3F20A4, 1);
    }

    .activeCarat {
        position: absolute;
        background: rgba($color: $main-gray-bg-color, $alpha: 1.0);
        width: 10px;
        height: 10px;
        margin: 0 auto;
        top: 0;
        clip-path: polygon(0 0, 50% 50%, 100% 0);
        transition: clip-path 1s;
    }
}