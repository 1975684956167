@import '../../../../../../styles/mixins/paragraph';
@import '../../../../../../styles/mixins/media-queries';

.onePostRootContainer {
    margin: 40px auto;

    .postTitle {
        @include paragraph(48px, #000);
    }

    .metaContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        p {
            @include paragraph(13px, #2C2C2C);
        }
    }

    .postContentContainer {
        p {
            @include paragraph(18px, #000, 30px);
            letter-spacing: 0.18px;
            font-family: 'Newsreader';
            margin-block-start: 1em;

        }
        
        figure {
            figcaption {
                @include paragraph(13px, #2C2C2C);
                margin: 10px 0;
                text-align: right;
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }

            img {
                max-width: 100%;
            }
        }

        iframe {
            max-width: 100%;
            margin: 30px;
            @include smMax {
                margin: 0;
                height: unset;
                margin-top: 20px;
            }
        }

        blockquote {
            @include paragraph(23px, #F70237, 25px);
            margin: 30px;
            line-height: 35px;
        }
    }
}