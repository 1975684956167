@import '../../styles/variables';
@import '../../styles/mixins/media-queries';
@import '../../styles/mixins/paragraph';

.infoRootContainer {
    @include smMax {
        grid-template-columns: 1fr;
        max-height: 100vh;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover
        }
    }
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
    overflow: hidden;

    div {
        &.imageContainer {
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;

            @include smMax {
                display: none;
            }
            img {
                max-width: 100%;
                object-fit: contain;
            }
        }

        p {
            @include paragraph(14px, #000, 25px);
        }

        h1 {
            @include paragraph(30px, #F70237, 25px);
            margin: 50px 0;
        }

        &.right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            @include smMax {
                height: calc(100vh - 50px);
                position: relative;
            }

            .rightInner {
                width: 320px;

                @include smMax {
                    height: 100%;
                    padding: 25px;
                    margin-top: 125px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        margin-bottom: 30px;
                        a {
                            @include paragraph(16px, #3F20A4, 25px);
                            span {
                                display: block;
                                margin-bottom: 7px;
                                line-height: 0;
                                img {
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}